.gshHeader {
  border-color: #a7c7a6;
  opacity: 27%;
  margin-bottom: 11px;
}

/* CodeMirror styling */
.CodeMirror {
  font-family: 'Ubuntu Mono';
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
